    import React from 'react'
    import cookie from 'react-cookies'
    import axios from 'axios'
    import {
      Card, Button, CardHeader, CardFooter, CardBody,
    CardTitle, CardText,Nav,FormText, Form, FormGroup, Label, Input,Container,Col,Row,Table,List,Dropdown,
    DropdownToggle,DropdownItem,
    DropdownMenu} from 'reactstrap'
    import {Helmet} from 'react-helmet'
    import StepWizard from "react-step-wizard"
    import Dropzone from 'react-dropzone'
    import {RiFileListLine,RiFileForbidLine} from "react-icons/ri";
    import {FaHandHoldingUsd,FaHandHoldingMedical} from "react-icons/fa";
    import {BiDotsVertical} from "react-icons/bi";
    import {AiOutlineAppstoreAdd} from "react-icons/ai";
    import {GiSettingsKnobs} from "react-icons/gi";
    import {RiSecurePaymentLine} from "react-icons/ri";
    import SimpleLineIcon from 'react-simple-line-icons'
    import {HiOutlineEmojiSad} from 'react-icons/hi'
    import { withTranslation } from 'react-i18next';
    import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
    import SimpleReactValidator from 'simple-react-validator'
    const MySwal = withReactContent(Swal)

    class Wallet extends React.Component{
      constructor(props) {
           super(props);
           this.state = {
             accs:[],
             gate : [],
             balance : [],
             checked : [],
             loader : true,
             mw : true,
             mode : 'accs',
             vouchers : []

           }
           this.validator = new SimpleReactValidator()
      }
    accountdetail =(e)=>{
    var id = e.currentTarget.getAttribute('id')
    var url = '/Panel/Account-Details/'+id
    this.props.history.push(url)
    }
    viewgate =() =>{
    var gid = this.state.gid
    var url = 'https://gateway.zarrinpay.com/'+gid+'/3299939993/10'
    window.open(url, "_blank")
    }
    editwebhook=()=>{
    this.setState({modal1 : !this.state.modal1})
    }
    onchange = (e) => {
      this.setState({
           [e.target.name]: e.target.value
      })
    }
    submitwebbhokadress = ()=>{
    const {t} = this.props
    if(this.state.newurl === ''){
     console.log(`${t('no-url')}`)
    }
    if (!this.validator.fieldValid('newurl')) {
        console.log(`${t('errform-newurl')}`)
        return
      }else{

      }
    const formdata = new FormData()
    formdata.append('webhook',this.state.newurl)
    formdata.append('gid',this.state.gid)
    axios.post('/api/gateway/editwebhook',formdata).then(response=>{
    if(response.data.status === 'success'){
      console.log(`${t('s-webhook')}`)
      this.getgate()
      this.editwebhook()
    }else{
      console.log(`${t('e-webhook')}`);
    }
    })
    }
    check = ()=>{
    if(!cookie.load('uid')){
    this.props.history.push('/')
    }
    }

    onDrop = (files) => {
    if(!cookie.load('uid')){
     this.props.history.push('/')
    }
    const { t } = this.props;
    if(!cookie.load('uid')){
     this.props.history.push('/')
    }
    this.setState({files})
    const formData = new FormData();
    formData.append('avatar',this.state.files[0])
    formData.append('gid',this.state.gid)
    if(this.state.files.length === 0){
      console.log(`${t('erf1')}`)
      return
    }
    if(this.state.files.length > 1){
      console.log(`${t('erf2')}`)
     this.setState({files : []})
      return
    }
    if(this.state.files[0].size > 2100000){
      console.log(`${t('erf3')}`)
     this.setState({files : []})
      return
    }

    if(this.state.files[0].type === 'image/jpg' || this.state.files[0].type === 'image/png' || this.state.files[0].type === 'image/jpeg' ){

    }else{
      console.log(`${t('erf4')}`)
      return
    }
    axios.post('/api/Gateway/Upload_Image', formData,{
        headers: {
            'content-type': 'multipart/form-data'
        }
    }).then(response=>{
      if(response.data.status === "error-fatal"){
        document.cookie = 'uid' +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        cookie.remove('uid')
        axios.get('/Users/logout')
        setTimeout(
        () => this.props.history.push('/'),
        1000
      );}
      if(response.data.status === 'success'){
        console.log('فایل با موفقیت ارسال شد.')

        this.getgate();

      }else{
        console.log('فایل ارسال نشد.')
      }
    })

    };
    componentWillMount(){
      this.check()
      window.scrollTo(0, 0)
      this.getgate()
    }
    getgate =()=>{
        this.check()
        let type =this.props.match.params.type;
        let gid =this.props.match.params.gid;
        let fiat =this.props.match.params.fiat;
        this.setState({type : type , gid :gid,fiat : fiat})
        const formdata = new FormData()
        formdata.append('type', type)
        formdata.append('gid',gid)
        axios.post('/api/Gateway/Getdetails',formdata).then(response=>{
         if(response.data.status === "error-fatal"){
           document.cookie = 'uid' +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
           cookie.remove('uid')
           axios.get('/Users/logout')
           setTimeout(
           () => this.props.history.push('/'),
           1000
         );}
         if(response.data.status === '0'){
           this.props.history.push('/panel/GateWay')
           console.log('درگاه فعال نیست')
         }
         if(response.data.status === 'success'){

           this.setState({
              accs : response.data.list,
              gate : response.data.gateway,
              balance : response.data.balance,
              gatefiat : response.data.gateway.fiat,
              gateurl : response.data.gateway.url,
              ghook : response.data.gateway.webhook,
              vouchers : response.data.vouchers
            })
           setTimeout(()=>this.setState({loader :false}))
         }else{

         }
        })
    }
    copyaddress =()=>{
    navigator.clipboard.writeText(this.state.gate.apikey)
    console.log('کپی شد')
    }
    withdraw= (e)=>{
        var gid = this.state.gid
        var id = e.currentTarget.getAttribute('id')
        // this.mw()
        var url = '/Panel/withdrawal-account/'+gid +'/'+id
        this.props.history.push(url)
    }
    setswap(anum){
    // console.log(anum);
    }
    mw=()=>{
      this.setState({mw : !this.state.mw})
    }
    setcheck(c,i){


    }
    set(a,b){
      this.setState({a : b})
    }
    switch_onChange_handle = (e)=>{
    const {t}=this.props
    var anum = e.currentTarget.getAttribute('anum')
    const formdata = new FormData()
    formdata.append('anum',anum)
    axios.post('/Accounts/swap_change',formdata).then(response=>{
    if(response.data.status === "error-fatal"){
    document.cookie = 'uid' +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    cookie.remove('uid')
    axios.get('/Users/logout')
    setTimeout(
    () => this.props.history.push('/'),
    1000
    );}
    if(response.data.status === 'error'){
    console.log(`${t('elsesc01')}`)
    }else{
    console.log(`${t('ssc01')}`)
    }
    })
    }
    viewgateurl =()=>{
      var url = 'https://' + this.state.gate.url
      window.open(url, "_blank")
    }
    render() {
        const acs = this.state.accs.map(list=>

          <tr className='text-medium'  id={list.anum}>
          <td>
            {list.usname}
          </td>
          <td>
            {list.balance}
          </td>
          <td>{list.price}</td>
          <td id={list.anum} onClick={this.accountdetail} className='pointer'><span className='text-medium py-2 px-3 btn-warning'>مشاهده</span></td>
          <td id={list.anum} onClick={this.withdraw} className='pointer'><span className='text-medium py-2 px-3 btn-info'>برداشت</span></td>
          </tr>
    )
    const { t } = this.props;
        return(
        <>
        <Helmet>
           <title>{t('gateway.title')}</title>
        </Helmet>
        <div >
          <div className='panel-container'>
            <div className='panel-container-left fixed'>
              <section className='mt-5 mx-2 text-dark lh-xl text-center'>
              <Card body className="radius shadow card-01">
                    <div className='text-center py-2'>
                      <img src={this.state.gate.logo} alt='gateway' className='gate-img'/>
                      <h4 className='mx-auto'>{this.state.gate.name}</h4>
                    </div>
                    <div className='d-flex justify-content-space-between py-2'>
                      <small>{t('balance : ')}{this.state.balance.usd}</small><small>$</small>
                    </div>
               </Card>
              </section>
              <section className='mt-5 card-light-left-section text-dark lh-xl h50'>
                <div className='pointer side-item' onClick={this.viewgateurl}>
                  <SimpleLineIcon name='globe mt-2' color='blue' /> <span>{this.state.gate.url}</span>
                </div>
              </section>
              <section className='mt-2 card-light-left-section text-dark lh-xl'>
              <div className='pointer side-item'>
                <SimpleLineIcon name='link mt-2' color='blue' /> <span>{this.state.ghook}</span>
                <SimpleLineIcon name='note ms-auto' color='blue' />
              </div>
              </section>

              <section className='mt-2 card-light-left-section text-dark lh-xl'>
              <div className='pointer side-item'>
                <SimpleLineIcon name='eye mt-2' color='blue' /> <span>{t('edit-logo-gate')}</span>
                <SimpleLineIcon name='note ms-auto' color='blue' />
              </div>
              </section>

            </div>
            <div className='panel-container-right'>

              <div className='content'>
              {this.state.gate.fiat === 'crypto' ? <>
              {this.state.mode === 'accs' ?
              <>
                <Table borderless responsive>
                  <thead>
                    <tr>
                      <th>
                      </th>
                      <th>{t('name')}
                      </th>
                      <th>{t('balance')}
                      </th>
                      <th>{t('price')}
                      </th>
                      <th>{t('view')}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                  {this.state.accs.map(acc=>
                    <tr id={acc.anum} onClick={this.accountdetail}>
                      <td><div className={acc.slug + '-icon'} />
                      </td>
                      <td>{acc.slug}
                      </td>
                      <td>{acc.balance}
                      </td>
                      <td>{acc.price}
                      </td>
                      <td><SimpleLineIcon name='eye' color='green' />
                      </td>
                    </tr>
                  )}
                  </tbody>
                </Table>
              </> : <></>} </> : <> </> }
              {this.state.gate.fiat === 'voucher' ? <>
              {this.state.mode === 'vouchers' ?
              <>
                <Table borderless responsive>
                  <thead>
                    <tr>
                      <th>
                      </th>
                      <th>{t('name')}
                      </th>
                      <th>{t('balance')}
                      </th>
                      <th>{t('price')}
                      </th>
                      <th>{t('view')}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                  {this.state.accs.map(acc=>
                    <tr id={acc.anum} onClick={this.accountdetail}>
                      <td><div className={acc.slug + '-icon'} />
                      </td>
                      <td>{acc.slug}
                      </td>
                      <td>{acc.balance}
                      </td>
                      <td>{acc.price}
                      </td>
                      <td><SimpleLineIcon name='eye' color='green' />
                      </td>
                    </tr>
                  )}
                  </tbody>
                </Table>
              </> : <></>} </> : <> </> }
              {this.state.mode === 'logo' ?
              <>

              </> : <></>}
              {this.state.mode === 'webhook' ?
              <>

              </> : <></>}
              <div>

              </div>
              <Row xs="1" sm="1" md="2" lg='2' xl='4' className='text-dark'>
                 <Col xl='12' className='m-auto text-center'>

                 </Col>
               </Row>
              </div>
            </div>
          </div>
        </div>

        </>
        )
      }
      }
      export default withTranslation()(Wallet)
