import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './style/main.css'
import './i18n.js'
import '@raha.group/persian-fonts/style.css'
import 'bootstrap/dist/css/bootstrap.min.css'
// import './bootstrap.min.css'
import "aos/dist/aos.css"
import 'shabnam-font/dist/font-face.css'
import 'samim-font/dist/font-face.css'
import * as serviceWorker from "./serviceWorker";
import ReactPWAInstallProvider from "react-pwa-install";
import PWAPrompt from 'react-ios-pwa-prompt'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
  <ReactPWAInstallProvider enableLogging>

  <PWAPrompt promptOnVisit={1} timesToShow={3} copyClosePrompt="Close" permanentlyHideOnDismiss={false}/>

    <App />
  </ReactPWAInstallProvider>
</React.StrictMode>
);
reportWebVitals();
serviceWorker.register();
