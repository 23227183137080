import React from 'react'
import cookie from 'react-cookies'
import axios from 'axios'
class Dashboard extends React.Component{
  constructor(props){
    super(props);
    this.state = {

    }
  }
  componentWillMount(){
    cookie.remove('uid', { path: '/' })
    this.props.history.push('/')
  }
  getdashbord =()=>{
    axios.get('/api/dashboard/getstate').then(response=>{
    })
  }
  render(){
    const {match} = this.props
    return(
    <>
      <div >
        <div className='panel-container'>
          <div className='panel-container-left fixed'>
            <div className='mt-10 mx-5 text-dark'>
              <li>ok</li>
            </div>
          </div>
          <div className='panel-container-right'>
            <div className='content'>
            </div>
          </div>
        </div>
      </div>

    </>
    )
  }
  }
  export default Dashboard
