import React from 'react'
import cookie from 'react-cookies'
import axios from 'axios'
import F from 'float'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {
   Modal,ModalFooter, ModalBody,Card, CardHeader,FormGroup,Form,Label, CardBody, InputGroup, InputGroupText, Input,Col,Row ,UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem,Button} from 'reactstrap'
import {Helmet} from 'react-helmet'
import {ReactQrcode} from 'reactjs-qrcode-generator'
import { useToasts } from 'react-toast-notifications'
import { withTranslation } from 'react-i18next'
import SimpleLineIcon from 'react-simple-line-icons'
import SimpleReactValidator from 'simple-react-validator'
import {Link} from 'react-router-dom'
const MySwal = withReactContent(Swal);

class Wallet extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      data : [],
      mode : 2 ,
      list : [],
      c1 : [],
      txs : [],
      coinname : '',
      cname : '',
      amount : 0,
      modal : false,
      n : 0,
      fee : 0,
      des : '',
      key : '3'
    }
this.validator = new SimpleReactValidator();
  }
  componentDidMount(){

    this.getwallet()
  }
  getwallet =()=>{
    this.chekcookie()
    axios.get('/api/wallet/deposit/all').then(response=>{
      if(response.data.status === "error-fatal"){
        document.cookie = 'uid' +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        cookie.remove('uid')
        axios.get('/Users/logout')
        setTimeout(
        () => this.props.history.push('/'),
        1000
      );}
      this.setState({list :  response.data , c1 : response.data[0],coinname : response.data[0].irname ,cname : response.data[0].usname ,coinPrice : response.data[0].irprice , fee : response.data[0].fee,})
    })
  }
  chekcookie = ()=>{
    let uid = cookie.load('uid')
    if(!uid){
      this.props.history.push('/')
      return
    }
  }
  toggle = ()=>{
    this.setState({dropdownOpen : !this.state.dropdownOpen})
  }

  setcointaker = (item) =>{
    this.setState({coinname : item.irname ,cname : item.usname ,coinPrice : item.irprice , fee : F.round(item.fee,10),c1 : item})
  }
  copyaddress =()=>{
    const { t } = this.props
    if(this.state.address !== ''){
      navigator.clipboard.writeText(this.state.c1.index0)
      console.log(
        <div dir='rtl'>
          <span className='rtl'>آدرس کپی شد</span>
        </div>,
      );
      }
  }


  setdes =(e)=>{
    this.setState({des: e.target.value})
  }
  onchange =(e)=>{
    this.setState({[e.target.name] : e.target.value})
  }
  setamountall =()=>{
    let balance = F.round(this.state.c1.balance,10)
    let amount = F.round(this.state.amount,10)

    let fee = F.round(this.state.c1.fee,10)
    let c = this.state.c1
    var n = F.round(balance - fee , 10)

    if(n < 0){
      this.setState({n : 0})
      return
    }
    if(n = 0){
      this.setState({n : 0})
      return
    }
    this.setState({n : n , fee : fee})
  }
  submit = (e) =>{
    e.preventDefault();
    const form = new FormData()
    form.append('dp',this.state.c1.index0)
    form.append('des',this.state.des)
    form.append('slug',this.state.c1.slug)
    form.append('amount',this.state.amount)
  }
  setwithdrawal = (e) =>{
    e.preventDefault();
    // this.setState({modal : !this.state.modal})
    if(this.state.des === ''){
      console.log('آدرس را وارد نکرده اید')
      return
    }
    if(this.state.n < this.state.fee){
      console.log('موجودی کافی نیست')
      return
    }
    const  formdata =  new FormData()
    formdata.append('accid' ,this.state.c1.acc_id)
    formdata.append('amount' ,this.state.n)
    formdata.append('fee' ,this.state.fee)
    formdata.append('from' ,this.state.c1.index0)
    formdata.append('to' ,this.state.des)
    formdata.append('tag' ,this.state.c1.tag)
    formdata.append('slug' ,this.state.c1.slug)
    axios.post('/api/withdrawal/submit/',formdata).then(response=>{
      if(response.data.status === 'error'){

      }
      if(response.data.status === 'waiting'){
        this.setState({modal : true , wid : response.data.Withdrawal_id,otp : response.data.otp})
      }
    })
  }
  withdraw = (e) =>{
     e.preventDefault();
     // this.setState({modal : !this.state.modal})
     const  formdata =  new FormData()
     formdata.append('accid' ,this.state.c1.acc_id)
     formdata.append('amount' ,this.state.n)
     formdata.append('fee' ,this.state.c1.fee)
     formdata.append('from' ,this.state.c1.index0)
     formdata.append('to' ,this.state.des)
     formdata.append('tag' ,this.state.c1.tag)
     formdata.append('slug' ,this.state.c1.slug)
     formdata.append('otp' ,this.state.otp)
     formdata.append('withdrawal_id' ,this.state.wid)
     axios.post('/api/withdrawal/submit_withdrawal/',formdata).then(response=>{
       if(response.data.status === 'error'){
         console.log(response.data.content)
         return
       }else{
         this.setState({modal : false , wid : response.data.withdrawal_id})
         console.log('برداشت با موفقیت انجام شد')
         // this.props.history.push('transations')
       }
     })
   }
setamount = (e)=>{
  this.setState({n : e.target.value})
}
  render(){

    const { t } = this.props;
    // console.log(this.state);
    var id =this.props.match.params.id
    return(
    <>
    <Helmet>
       <title>{t('profile.title')}</title>
    </Helmet>
    <div className='rtl'>

          <Card>
          <CardHeader className='button-row rtl'>
            <Link to='depositcrypto'  className='ms-3 text-white'>واریز</Link>
            <Link to='depositirt' className='mx-3 text-white'> واریز ریالی </Link>
            <Link to='withdrawall' className= 'active-tabs mx-3'>برداشت </Link>
            <Link to='withdrawirt'  className= 'mx-3 text-white'>برداشت ریالی</Link>
          </CardHeader>
            <CardBody>
            <div className='mx-auto w-75'>

              <div className='add-containe mt-5' dir='rtl'>
              <Form className='justify-content-space-between'>
                <FormGroup>
                <div   className='ltr'>
                <UncontrolledDropdown className='ticker-list-2 m-auto'>
                  <DropdownToggle color='light' block>
                {this.state.coinname === '' ?  'یک ارز انتخاب کنید' :
                <div className='f14 d-flex justify-content-space-between'>
                  <span className={this.state.c1.slug+'-icon cr-icon pt-2'}></span>

                  <span className='mr-5 pt-2 pb-1'>{this.state.coinname}</span>
                  <span className='slug-crypto mr-auto pt-2 pb-1'>({this.state.c1.slug})</span>

                </div>
              }
                  </DropdownToggle>
                  <DropdownMenu className='w-100 modifier'>
                  {this.state.list.map(item=>
                    <>
                      <DropdownItem active={item.usname === 'usdt' ? true : false} data-name={item.irname} id={item.slug} data-name={item.usname} onClick={()=>this.setcointaker(item)}>
                      <div className='d-flex justify-content-space-between'>
                        <span className={item.slug+'-icon cr-icon mt-2'}></span>
                        <span className='mr-5 mt-2'>{item.irname}</span>
                        <span className='slug-crypto mt-2 mr-auto'>({item.slug})</span>
                      </div>
                      </DropdownItem>
                      <DropdownItem divider />
                    </>
                  )}
                  </DropdownMenu>
                </UncontrolledDropdown>
                </ div>
                </FormGroup>
                <FormGroup  >

                < div  >
                <InputGroup className='rtl'>

                   <InputGroupText onClick={this.setamountall} className="addon-right-primary" addon>
                     QRC
                   </InputGroupText>
                   <Input
                     onChange={this.setdes}
                     className=''
                     id="des"
                     name="des"
                     placeholder="آدرس مقصد"
                     type="text"
                   />
                 </InputGroup>

                </ div>
                </FormGroup>
              {this.state.cname === 'binance' ||this.state.cname === 'ripple' ||this.state.cname === 'stellar' ?
                <FormGroup  >

                < div  >
                  <Input
                    onChange={this.onchange}
                    className=''
                    id="tag"
                    name="tag"
                    placeholder="تگ / یادداشت / ممو"
                    type="text"
                  />
                </ div>
                </FormGroup> : <></> }
                <FormGroup  >

                < div  >
                <InputGroup className='rtl'>

                   <InputGroupText onClick={this.setamountall} className="addon-right" addon>
                     همه
                   </InputGroupText>
                   <Input
                   addon
                    onChange={this.setamount}
                     className=''
                     id="amount"
                     name="amount"
                     placeholder="مقدار انتقال"
                     type="number"
                     min = '0'
                     value ={this.state.n}
                   />
                 </InputGroup>
                 {this.state.coinname !== '' ?
                 <>

                 <Row className='withdrawal-info radius rtl mt-5' xs="1" sm="2" md="3" xl='2' lg='2'>
                 <Col xl='6' xs='12'>
                   <small className='px-3'><span>{'موجودی ('+ this.state.c1.slug +"): "}</span><span className='fiat-span'>{this.state.c1.balance}</span></small>
                   <br/>
                   <small className='px-3'><span>موجودی (IRT):</span><span className='fiat-span'>{this.state.c1.balance}</span></small>
                 </Col>
                 <Col  xl='6' xs='12'>
                 <small className='px-3'><span>قیمت (USDT):</span><span className='fiat-span'>{this.state.c1.price }</span></small>
                 <br/>
                 <small className='px-3'><span>قیمت  (IRT): </span><span className='fiat-span'>{this.state.c1.irprice}</span></small>
                 </Col>
                 <Col   xl='6' xs='12'>
                 <small className='px-3'><span>{'کارمزد انتقال (' +this.state.c1.slug +"): "} </span><span className='fiat-span'>{this.state.c1.fee }</span></small>
                 <br/>
                 </Col>
                 </Row>

                 </>
                 :<></>}
                </div>
                </FormGroup>

                <FormGroup   className=''>
                <Label

                >

                </Label>
                  < div  >
                  <Button type='submit' block color='success' onClick={this.setwithdrawal}>ثبت</Button>
                  </ div>
                </FormGroup>

                </Form>
              </div>
            </div>

            </CardBody>
            </Card>

    </div>
    <Modal isOpen={this.state.modal}  className='otp-modal'>
        <ModalBody>
          <p>برای تکمیل فرآیند برداشت کد اعتبارسنجی ارسال شده  را وارد نمائید</p>
          <FormGroup  >
          < div>
            <Input
              onChange={this.onchange}
              className=''
              id="otp"
              name="otp"
              placeholder="کد اعتبارسنجی"
              type="text"
              value = {this.state.otp}
            />
            {this.state.wid}
          </ div>
          </FormGroup>
        </ModalBody>

        <ModalFooter>
          <Button color="primary" onClick={this.withdraw}>برداشت</Button>{' '}
          <Button color="secondary" onClick={() => this.setState({modal : false})}>انصراف</Button>
        </ModalFooter>
      </Modal>

    </>
    )
  }
  }
  export default withTranslation()(Wallet)
