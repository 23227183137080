import React from 'react'
import axios from 'axios'
import {
CardHeader,
CardBody,
CardText,
Form,FormGroup,Input,Button,
Card,Container,Col,Table} from 'reactstrap'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import SimpleLineIcon from 'react-simple-line-icons'
import cookie from 'react-cookies'
const MySwal = withReactContent(Swal)

class register extends React.Component {
     constructor(props) {
          super(props);
          this.state = {


          }
     }
     submit = (e) => {
       e.preventDefault()
       const formdata = new FormData()
       formdata.append('subject',this.state.subject)
       formdata.append('content',this.state.content)
       formdata.append('category',this.state.category)
       formdata.append('type',this.state.type)
       formdata.append('subcategory',this.state.subcategory)

       axios.post('/api/post/create_new_post',formdata).then(response=>{
         console.log(response.data.message)
       })
     }
     onChange = (e) => {
          this.setState({
               [e.target.name]: e.target.value
          })
     }

     componentWillMount(){

}
     render() {
          return (
  <Container  >
  <Card className='shadow radius'>
  <CardHeader className='text-success rtl' tag="h6">new post</CardHeader>
  <CardBody className='rtl text-small'>
    <Form>
      <FormGroup>
      <Input onChange={this.onChange} name='type' id='type' placeholder='type' />
      </FormGroup>
      <FormGroup>
      <Input onChange={this.onChange} name='category' id='category' placeholder='category' />
      </FormGroup>
      <FormGroup>
      <Input onChange={this.onChange} name='subcategory' id='subcategory' placeholder='subcategory' />
      </FormGroup>
      <FormGroup>
      <Input onChange={this.onChange} name='subject' id='subject' placeholder='Subject' />
      </FormGroup>
      <FormGroup>
      <Input onChange={this.onChange} type='textarea' name='content' id='content' placeholder='content' />
      </FormGroup>

      <FormGroup>
        <Button onClick={this.submit} color='primary' className='main-buttun'>ثبت</Button>
      </FormGroup>
    </Form>
  </CardBody>


  </Card>

  </Container>

     )

}}
export default register
