import React from 'react'
import axios from 'axios'
import {
     List,Table,Button, Card,DropdownItem, FormGroup, Label, Input,Container,Col,Row} from 'reactstrap'
import cookie from 'react-cookies'

import withReactContent from 'sweetalert2-react-content'
import SimpleLineIcon from 'react-simple-line-icons'
import { useTranslation, withTranslation, Trans } from 'react-i18next'
import {Link,withRouter} from 'react-router-dom'
import Pagination from '../txpagination'
import Accountpag from '../accountpg'


class register extends React.Component {
     constructor(props) {
          super(props);
          this.state = {
            user : [],
            list : [],
            transactions : [],
          }
     }

componentWillMount(){
  if(!cookie.load('uid')){
    this.props.history.push('/')
    return
  }
  this.get_user()
}
get_user= () =>{
  let id =this.props.match.params.id;
  this.setState({ id :id})
  const formdata = new FormData()
  formdata.append('id',id)
  axios.post('/api/Admin/Get_user_details',formdata).then(response=>{
    this.setState({user : response.data.user , list : response.data.accounts , transactions : response.data.transactions})
  })
}
activate =()=>{
  var email = this.state.user.email
  const formdata = new FormData()
  formdata.append('email',email)
  axios.post('/api/admin/activate_user',formdata).then(response=>{
    if(response.data.status === 'success'){
      alert('کاربر غیر فعال شد')
      this.get_user()
    }else{
      alert('مشکلی پیش آمده است');
    }
  })
}
deactive =()=>{
  var email = this.state.user.email
  const formdata = new FormData()
  formdata.append('email',email)
  axios.post('/api/admin/deactive_user',formdata).then(response=>{
    if(response.data.status === 'success'){
      alert('کاربر غیر فعال شد')
      this.get_user()
    }else{
      alert('مشکلی پیش آمده است');
    }
  })
}
active =()=>{
  var email = this.state.user.email
  const formdata = new FormData()
  formdata.append('email',email)
  axios.post('/api/admin/activate_user',formdata).then(response=>{
    if(response.data.status === 'success'){
      alert('کاربر فعال شد')
      this.get_user()
    }else{
      alert('مشکلی پیش آمده است');
    }
  })
}
acceptdoc =()=>{
  var email = this.state.user.email
  const formdata = new FormData()
  formdata.append('email',email)
  axios.post('/api/admin/acceptdoc',formdata).then(response=>{
    if(response.data.status === 'success'){
      alert(response.data.message)
      this.get_user()
    }else{
      alert(response.data.message)
    }
  })
}
denydoc =()=>{
  var email = this.state.user.email
  const formdata = new FormData()
  formdata.append('email',email)
  axios.post('/api/admin/denydoc',formdata).then(response=>{
    if(response.data.status === 'success'){
      alert('مدارک کاربر رد شد')
      this.get_user()
    }else{
      alert('مشکلی پیش آمده است');
    }
  })
}
activewithdraw =()=>{
  var email = this.state.user.email
  const formdata = new FormData()
  formdata.append('email',email)
  axios.post('/api/admin/activewithdraw',formdata).then(response=>{
    if(response.data.status === 'success'){
      alert('اجازه برداشت داده شد')
      this.get_user()
    }else{
      alert('مشکلی پیش آمده است');
    }
  })
}
deactivewithdraw =()=>{
  var email = this.state.user.email
  const formdata = new FormData()
  formdata.append('email',email)
  axios.post('/api/admin/deactivewithdraw',formdata).then(response=>{
    if(response.data.status === 'success'){
      alert('اجازه برداشت داده شد')
      this.get_user()
    }else{
      alert('مشکلی پیش آمده است');
    }
  })
}
deposit =(i)=>{
  this.props.history.push('/admin/deposit-by-admin/' + i.acc_id)
}
withdraw =(i)=>{
  this.props.history.push('/admin/withdraw-by-admin/' + i.acc_id)
}



     render() {
const { t } = this.props;
const user = this.state.user
          return (
               <>
               <h5 className='rtl'>مشخصات کاربر</h5>
               <span onClick={  this.get_user} className='mr-auto ml-3 pointer text-medium'>بارگزاری مجدد</span>
               <Card body className='radius shadow my-3 rtl p'>
                <Row  className=' my-3 ' md='2' sm='1' lg='2'>
                  <Col>
                  <Table size="sm" responsive striped borderless>
                  <tr>
                    <td>نام
                    </td>
                    <td>
                    {user.name}
                    </td>
                  </tr>


                  <tr>
                    <td>ایمیل
                    </td>
                    <td>{user.email}
                    </td>
                  </tr>


                  <tr>
                    <td>موبایل
                    </td>
                    <td>{user.mobile}
                    </td>
                  </tr>



                  <tr>
                    <td>کد کاربر
                    </td>
                    <td>{user.userid}
                    </td>
                  </tr>



                  <tr>
                    <td>مدارک
                    </td>
                    <td>{user.completed === '2' ? 'تایید شده' : 'تایید نشده'}
                    </td>
                  </tr>




                  </Table>
                  <Row className=''>
                  <Col className='text-center m-auto'>
                  {this.state.user.docurl !== '' ? <img src={this.state.user.docurl} width='500px' /> : <></> }
                  </Col>

                  <Col className='text-center m-auto'>
                  {this.state.user.docurl2 !== '' ? <img src={this.state.user.docurl2} width='500px' /> : <></> }
                  </Col>
                  </Row>
                  </Col>
                </Row>
                  <div className='d-table justify-content-between mt-5'>
                  <Button onClick={this.acceptdoc} className=' text-center mx-3 '  color='info'>تائید مدارک</Button>
                  <Button onClick={this.denydoc} className=' text-center mx-3 '  color='warning'>رد مدارک</Button>
                  <Button onClick={this.deactive} className=' text-center mx-3 '  color='danger'>غیر فعال کردن</Button>
                  <Button onClick={this.active} className=' text-center mx-3 '  color='success'>فعال کردن</Button>
                  <Button onClick={this.activewithdraw} className=' text-center mx-3 '  color='success'>اجازه برداشت</Button>
                  <Button onClick={this.deactivewithdraw} className=' text-center mx-3 '  color='danger'>عدم برداشت</Button>
                </div>
               </Card>
               <h5 className='rtl mt-5'>تراکنش های {this.state.user.name}</h5>
                <Pagination data={this.state.transactions} />

               <h5 className='rtl mt-5'>حساب های {this.state.user.name}</h5>
               <Card body className='radius shadow my-3 rtl'>
               {this.state.list.map(i=>
                 <Row key={i.id} className='coin-list-light rtl py-2 ' xl='6'>
                   <Col ><p className='coin-list-2 mr-2' >{i.slug}<span className='text-muted'></span><span className={i.slug.toLowerCase() +'-icon ml-2 my-auto'}></span></p></Col>
                   <Col><p className='coin-list-2'>{i.balance}</p></Col>
                   <Col><p className='coin-list-2'>{i.balance * i.price}</p></Col>
                   <Col><p className='coin-list-2'>{i.changePrice}</p></Col>

                   <Col xl='4' className='d-flex justify-content-space-around ltr text-small'>
                   <p onClick={()=>this.deposit(i)} className='text-success pointer' data-id={i.acc_id} data-slug={i.slug}>واریز</p>
                   <p onClick={()=>this.withdraw(i)} className='mx-2 pointer'  >برداشت</p>
                   <p onClick={()=>this.transactions(i)} className='mx-2 pointer'>تراکنش ها</p>
                   <p onClick={()=>this.freze(i)} className='mx-2 pointer'>فریز</p>
                   <p onClick={()=>this.update(i)} className='pointer'>آپدیت</p>
                  </Col>
                 </Row>

               )}

               </Card>

               </>

     )
     }
}
export default withRouter(withTranslation()(register))
