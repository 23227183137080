import React from 'react'
import axios from 'axios'
import SimpleDateTime  from 'react-simple-timestamp-to-date'
import {
  Card, Button, CardHeader, CardFooter, CardBody,
CardTitle, CardText,Nav,NavLink, Form, FormGroup, Label, Input,Container,Col,Row,Table,List} from 'reactstrap'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import cookie from 'react-cookies'
import {RiDraftFill,RiFileListLine,RiFileForbidLine,RiFileEditLine,RiEmotionUnhappyLine } from "react-icons/ri";
import SimpleLineIcon from 'react-simple-line-icons'
import {Link} from 'react-router-dom'
import {withRouter} from 'react-router-dom'
import { useTranslation, withTranslation, Trans } from 'react-i18next';
const MySwal = withReactContent(Swal)

class Layer extends React.Component{
  constructor(props) {
       super(props);
       this.state = {
         d :[]
       }
 }

  getlist = ()=>{
    axios.get('/api/admin/ttm/getlist').then(response=>{
      this.setState({lists : response.data})
    })
  }
    componentDidMount(){
  }

componentWillMount(){
  if(!cookie.load('uid')){
    this.props.history.push('/')
    return
  }
axios.get('/api/admin/getdahsboard').then(response=>{
  var d = response.data

  this.setState({
    d : d,
    wgc : d.waitingusers.count,
    nu : d.users.count,
    dtc : d.dtxs.count,
  })

})

}


  render(){
      const { t } = this.props;
    return(
      <>
      <div className='pt-10'>
      <Row xs="1" sm="2" md="2" lg='2' xl='4'>
         <Col>
         <Link to='/gateways'>
           <Card body className="rtl m-2 radius shadow">
              <CardTitle className="">
                <div className='justify d-flex'>
                  <p>واریز ها</p>
                  <RiDraftFill color='orange' size='2rem'/>
                </div>

                  <h3 className='text-center text-primary mt-2'>{this.state.wgc}</h3>
              </CardTitle>
                <CardText className='text-center'>
                <small className='text-success text-center'></small>
                </CardText>
            </Card>
            </Link>
         </Col>

         <Col>
         <Link to='/admin/users/all-users'>
         <Card body className="rtl m-2 radius shadow">
         <CardTitle className="">
           <div className='justify d-flex'>
             <p>کاربران</p>
             <RiDraftFill color='orange' size='2rem'/>
           </div>
         </CardTitle>
           <CardText className='d-none ltr d-sm-block'>
           <h3 className='text-center text-primary mt-2'>{this.state.nu}</h3>
           </CardText>
          </Card>
          </Link>
         </Col>
         <Col>
         <Card body className="rtl m-2 radius shadow">
         <CardTitle className="">
           <div className='justify d-flex'>
             <p>دارایی ها</p>
             <RiDraftFill color='orange' size='2rem'/>
           </div>

         </CardTitle>
           <CardText className='d-none ltr d-sm-block'>
           <h3 className='text-center text-primary mt-2'>{this.state.dtc}</h3>
           </CardText>
          </Card>
         </Col>
         <Col>
         <Card body className="rtl m-2 radius shadow">
         <CardTitle className="">
           <div className='justify d-flex'>
             <p>دارایی کوکوین</p>
             <RiDraftFill color='orange' size='2rem'/>
           </div>

         </CardTitle>
           <CardText className='d-none ltr d-sm-block'>
           <h3 className='text-center text-primary mt-2'>{this.state.dtc}</h3>
           </CardText>
          </Card>
         </Col>
         <Col>
         <Card body className="rtl m-2 radius shadow">
         <CardTitle className="">
           <div className='justify d-flex'>
             <p>معاملات ساده</p>
             <RiDraftFill color='orange' size='2rem'/>
           </div>

         </CardTitle>
           <CardText className='d-none ltr d-sm-block'>
           <h3 className='text-center text-primary mt-2'>{this.state.dtc}</h3>
           </CardText>
          </Card>
         </Col>
         <Col>
         <Card body className="rtl m-2 radius shadow">
         <CardTitle className="">
           <div className='justify d-flex'>
             <p>معاملات بازار</p>
             <RiDraftFill color='orange' size='2rem'/>
           </div>

         </CardTitle>
           <CardText className='d-none ltr d-sm-block'>
           <h3 className='text-center text-primary mt-2'>{this.state.dtc}</h3>
           </CardText>
          </Card>
         </Col>
         <Col>
         <Link to='/posts'>
         <Card body className="rtl m-2 radius shadow">
         <CardTitle className="">
           <div className='justify d-flex'>
             <p>نوشته ها و پست ها</p>
             <RiDraftFill color='orange' size='2rem'/>
           </div>

         </CardTitle>
           <CardText className='d-none ltr d-sm-block'>
           <h3 className='text-center text-primary mt-2'>{this.state.dtc}</h3>
           </CardText>
          </Card>
         </Link>
         </Col>
       </Row>
       <div className='d-none'>
      <Row xl='4' lg='2' md='2' sm='2' xs='1'>

      </Row>
      <Row>
        <Col>
        <Card body className="rtl m-2 radius shadow">
           <CardTitle className="mt-3 font-weight-light" tag="h5">{t('موجودی تتر')}</CardTitle>

           <CardText className='ltr'>

           </CardText>
         </Card>
        </Col>
      </Row>
      <Row>
        <Col>
        <Card body className="rtl m-2 radius shadow">
           <CardTitle className="mt-3 font-weight-light" tag="h5">{t('Last transactions')}</CardTitle>
           <CardText className='d-none  d-sm-block'>

           </CardText>
         </Card>
        </Col>
      </Row>
      <Row>
        <Col>
        <Card body className="rtl m-2 radius shadow">
           <CardTitle className="mt-3 " tag="h5">{t('دارایی ها')}</CardTitle>
           <CardText className='d-none ltr d-sm-block'>

           </CardText>
         </Card>
        </Col>
      </Row>
      <Row xs="1" sm="2" md="2" lg='2' xl='4' >
         <Col>
          <Link to="Profile">
          <Card body className="text-center m-2 radius shadow">
          <span className="icon">
          <SimpleLineIcon name="user-following" size='large'  />
          </span>
             <CardTitle className="mt-3 " tag="h5">{t('Verification')}</CardTitle>
             <CardText className='d-none  d-sm-block'>{t('Verification&Profile')}</CardText>
           </Card>
          </Link>
         </Col>
         <Col><Link to="Transactions">
         <Card body className="text-center m-2 radius shadow">
         <span className="icon">
          <SimpleLineIcon name="credit-card" size='large'  />
          </span>
            <CardTitle className="mt-3 " tag="h5">{t('Transactions')}</CardTitle>
            <CardText className='  d-none d-sm-block'>{t('Deposit&withdraw')}</CardText>
          </Card>
         </Link>
         </Col>
         <Col><Link to="Wallets">
         <Card body className="text-center m-2 radius shadow">
         <span className="icon">
          <SimpleLineIcon name="wallet" size='large'  />
          </span>
            <CardTitle className="mt-3 " tag="h5">{t('Wallets')}</CardTitle>
            <CardText className=' d-none d-sm-block'>{t('Wallets&transfers')}</CardText>
          </Card>
         </Link>
         </Col>
         <Col><Link to="messages">
         <Card body className="text-center m-2 radius shadow">
         <span className="icon">
          <SimpleLineIcon name="bell" size='large'  />
          </span>
            <CardTitle className="mt-3 " tag="h5">{t('Messages')}</CardTitle>
            <CardText className=' d-none d-sm-block'>{t('Messagecenter')}</CardText>
          </Card>
         </Link></Col>
       </Row>
       </div>
      </div>
      </>
    )
  }
}
export default withRouter(withTranslation()(Layer))
