import React from 'react'
import cookie from 'react-cookies'
import axios from 'axios'
import { Button, Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import {Helmet} from 'react-helmet'
import {RiFileListLine,RiFileForbidLine} from "react-icons/ri";
import {FaHandHoldingUsd,FaHandHoldingMedical} from "react-icons/fa";
import {BiDotsVertical} from "react-icons/bi";
import SimpleLineIcon from 'react-simple-line-icons'
import { withTranslation } from 'react-i18next';
import SimpleReactValidator from 'simple-react-validator'
class Wallet extends React.Component{
  constructor(props){
    super(props);
    this.state = {

    }
    this.validator = new SimpleReactValidator();
  }
onChange = (e)=>{
  this.setState({[e.target.name] : e.target.value})
}

  render(){
    const { t } = this.props;
    // console.log(this.state.data);
    return(
    <div className='rtl bg-white mt-5 p-5'>
    <p>Add New Coin</p>
    <Form>
        <FormGroup>
          <Label for="name">name</Label>
          <Input onChange={this.onChange} type="name" name="name" id="name" placeholder="نام ارز" />
        </FormGroup>
        <FormGroup>
          <Label for="slug">slug</Label>
          <Input onChange={this.onChange}  type="slug" name="slug" id="slug" placeholder="نماد" />
        </FormGroup>
        <FormGroup>
          <Label for="Chain">Chain</Label>
          <Input onChange={this.onChange} type="text" name="Chain" id="Chain" >
          </Input>
        </FormGroup>
        <Button onClick={this.submit}>Submit</Button>
      </Form>
    </div>
    )
  }
  }
  export default withTranslation()(Wallet)
