import React from 'react'

import {Row,Col} from 'reactstrap'
import {
  Switch,  BrowserRouter as Router,withRouter,Route
} from "react-router-dom"
import axios from 'axios'
import SimpleLineIcon from 'react-simple-line-icons'
import Home from './pages/admin/login'
import Admin from './pages/admin'
class PanelLayer extends React.Component{
  constructor(props){
    super(props);
    this.state = {

    }
  }

  render(){
    return(
    <>
      <div className='panel-wp'>
        <Router>
          <Switch>
            <Route
              path='/' exact
              render={(props) => <Home {...props} />}
            />
            <Route
              path='/Admin'
              render={(props) => <Admin {...props} />}
            />
          </Switch>
        </Router>
      </div>
    </>
    )
  }
  }
  export default PanelLayer
