import React from 'react'
import axios from 'axios'
import {
CardHeader,
CardBody,InputGroup, InputGroupText, InputGroupAddon, Input,
Card,Col,Row,Table} from 'reactstrap'
import SimpleLineIcon from 'react-simple-line-icons'
import cookie from 'react-cookies'
import Pagination from '../pagination'
class register extends React.Component {
     constructor(props) {
          super(props);
          this.state = {
               users: [],
               type : 'all-users'

          }
     }
     onChange = (e) => {
          this.setState({
               [e.target.name]: e.target.value
          })
     }

     componentWillMount(){
       if(!cookie.load('uid')){
         this.props.history.push('/')
         return
       }
       var type =this.props.match.params.type
       this.setState({type : type})
       axios.get('/api/Admin/get_users/').then(response=>{
         var d = response.data
         this.setState({
          users : d.users.data,

         })
       })
     }
     componentDidMount(){

     }
     refresh = ()=>{
       axios.get('/api/Admin/get_users/').then(response=>{
         var d = response.data
         this.setState({
          users : d.users.data,

         })
       })
     }
view = (e)=>{
  var id = e.currentTarget.getAttribute('id')
  var url = '/admin/User-detail/'+id
  this.props.history.push(url)
}
     render() {
       let type = this.props.match.params.type
       let list = []
       let status = ''
       switch (type) {
         case 'deactive-users':
           list = this.state.users.filter(item => item.completed === '0');
           status = 'unsent'
           break;
         case 'active-users':
           list = this.state.users.filter(item => item.completed === '2');
           status = 'verified'
           break;
         case 'waiting-users':
           list = this.state.users.filter(item => item.completed === '1');
           status = 'waiting'
           break;
         case 'rejected-users':
           list = this.state.users.filter(item => item.completed === '3');
           status = 'rejected'
           break;
         case 'blocked-users':
           list = this.state.users.filter(item => item.completed === '5');
           status = 'blocked'
           break;
         default:
           list = this.state.users
       }
          return (

      <Card className='shadow radius'>
        <div className='d-flex pr-4 pl-2 pb-2 pt-3 radius bg-white rtl' tag="h6">
          <SimpleLineIcon name='user' />
          <span className='mr-3'>همه کاربران</span>
          <span onClick={this.refresh} className='mr-auto ml-3 pointer text-medium'>بارگزاری مجدد</span>

        </div>
        <CardBody className='rtl text-small'>
        <Pagination data={list} />
          <Table className='d-none' size='sm' hover borderless responsive>
          <tr>
          <th>#</th>
          <th>
            ID
          </th>
          <th>
          email
          </th>
          </tr>
        {list.map(g=>
          <tr className='pointer' onClick={this.view} id={g.userid}>
            <th className='py-2'>{g.id}</th>
            <td >
            {g.userid}
            </td>
            <td>
            {g.email}
            </td>
            <td>
            {g.mobile}
            </td>
            <td>
            {status}
            </td>
          </tr>
        )}
        </Table>
        </CardBody>
      </Card>

     )

}}
export default register
