import React from 'react'
import axios from 'axios'
import {
CardHeader,
CardBody,
CardText,
Form,FormGroup,Input,Button,
Card,Container,Col,Table} from 'reactstrap'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import SimpleLineIcon from 'react-simple-line-icons'
import cookie from 'react-cookies'
const MySwal = withReactContent(Swal)

class register extends React.Component {
     constructor(props) {
          super(props);
          this.state = {


          }
     }
     submit = (e) => {
       e.preventDefault()
       const formdata = new FormData()
       formdata.append('symbol',this.state.symbol)
       formdata.append('name',this.state.name)
       formdata.append('irname',this.state.irname)
       formdata.append('chain',this.state.chain)
       formdata.append('token',this.state.token)
       formdata.append('supply',this.state.supply)
       formdata.append('fee',this.state.fee)
       formdata.append('confidence',this.state.confidence)
       formdata.append('description',this.state.description)
       axios.post('/api/vc/create_new_coin',formdata).then(response=>{
         console.log(response.data.message)
       })
     }
     onChange = (e) => {
          this.setState({
               [e.target.name]: e.target.value
          })
     }

     componentWillMount(){

}
     render() {
          return (
  <Container  >
  <Card className='shadow radius'>
  <CardHeader className='text-success rtl' tag="h6">ارز جدید</CardHeader>
  <CardBody className='rtl text-small'>
    <Form>
      <FormGroup>
      <Input onChange={this.onChange} name='name' id='name' placeholder='name' />
      </FormGroup>
      <FormGroup>
      <Input onChange={this.onChange} name='Chain' id='Chain' placeholder='Chain' />
      </FormGroup>
      <FormGroup>
      <Input onChange={this.onChange} name='supply' id='supply' placeholder='supply' />
      </FormGroup>
      <FormGroup>
      <Input onChange={this.onChange} name='irname' id='irname' placeholder='irname' />
      </FormGroup>
      <FormGroup>
      <Input onChange={this.onChange} name='symbol' id='symbol' placeholder='symbol' />
      </FormGroup>
      <FormGroup>
      <Input onChange={this.onChange} name='token' id='token' placeholder='token' />
      </FormGroup>
      <FormGroup>
      <Input onChange={this.onChange} name='description' id='description' placeholder='description' />
      </FormGroup>
      <FormGroup>
      <Input onChange={this.onChange} type='number' name='fee' id='fee' placeholder='fee' />
      </FormGroup>
      <FormGroup>
      <Input onChange={this.onChange} name='confidence' id='confidence' placeholder='confidence' />
      </FormGroup>
      <FormGroup>
        <Button onClick={this.submit} color='primary' className='w-25'>ثبت</Button>
      </FormGroup>
    </Form>
  </CardBody>


  </Card>

  </Container>

     )

}}
export default register
