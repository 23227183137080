import React from 'react'
import {Row,Col,List,UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem,Button} from 'reactstrap'
import axios from 'axios'
import CryotpList from './trd'
class Homepage extends React.Component{
  constructor(props){
    super(props);
    this.state={
      coin : 'BTC',
      coinname : 'بیت کوین',
      coinPrice : 16800,
      buysell : 'buy',
      fee : '',
      list : []
    }
  }
  componentWillMount(){
    axios.get('/api/exchange/get_list').then(response=>{
      this.setState({list : response.data})
    })
  }
  componentDidMount(){
    this.setlist()
  }
  setlist = ()=>{

  }
  setcointaker = (item) =>{
    this.setState({coinname : item.name , coinPrice : item.irprice , fee : item.fee,c1 : item})
  }
  setcointicker = (item) =>{
    this.setState({coinname2 : item.name , coinPrice2 : item.irprice , fee2 : item.fee,c2 : item})
  }
render(){
  return(
    <div className='rtl' id='homepage'>
      <h5 className='c-i'>Dashboard</h5>
      <small>overview of market and states</small>
      <Row xl='2' lg='2' md='1' sm='1'>
        <Col>
          <CryotpList />
        </Col>
        <Col xl='3'>
            <div className='bg-white hv-100 p-3 bg-white'>
            <Row className='text-center font-weight-bold h-50'>
              <Col className='text-center'><p onClick={() =>this.setState({buysell : 'buy'})}  className='text-center pointer'>BUY</p>
              </Col>
              <Col size='1' className='text-center'>
                <hr className='hr-vertical'/>
              </Col>
              <Col className='text-center'><p onClick={() =>this.setState({buysell : 'sell'})} className='text-center pointer'>SELL</p>
              </Col>
            </Row>
            <p className='text-center rtl mt-4'>قیمت {this.state.coinname} </p>
            <p className='font-weight-bold'>{this.state.coinPrice} USDT</p>
            <div className='ticker my-3'>
            <UncontrolledDropdown>
            <DropdownToggle color='light' block caret>
            Dropdown
            </DropdownToggle>
            <DropdownMenu className='w-100 modifier'>
            {this.state.list.map(item=>
              <>
              <DropdownItem active={item.name === 'ethereum' ? true : false} data-name={item.name} id={item.slug} data-name-fa={item.name} data-price={item.price} data-price-irt={item.irprice} onClick={()=>this.setcointaker(item)}>
                <div className='d-flex justify-content-space-between'>
                  <p className={item.slug}></p>
                  <p>{item.name}</p>
                  <small>{item.price}</small>
                </div>
              </DropdownItem>
              <DropdownItem divider />
              </>
            )}
            </DropdownMenu>
            </UncontrolledDropdown>
            </div>
            <div className='taker'>
            <UncontrolledDropdown  >
                  <DropdownToggle color='light' block caret>
                    Dropdown
                  </DropdownToggle>
                  <DropdownMenu className='w-100 modifier'>
                  {this.state.list.map(item=>
                    <>
                    <DropdownItem active={item.name === 'bitcoin' ? true : false} data-name={item.name} id={item.slug} data-name-fa={item.name} data-price={item.price} data-price-irt={item.irprice}  onClick={this.setcointicker}>
                    <div className='d-flex justify-content-space-between'>
                      <p className={item.slug}></p>
                      <p>{item.name}</p>
                      <small>{item.price}</small>
                    </div>
                  </DropdownItem>
                    <DropdownItem divider />
                    </>
                  )}
                  </DropdownMenu>
                </UncontrolledDropdown>
            </div>
            <div className='my-3 rtl'>
            <small>{this.state.fee}</small>
            {this.state.buysell === 'buy' ? <Button block color='success' >خرید</Button> :
            <Button block color='danger' >فروش</Button> }
            </div>
            <div>
              <h6 className='text-muted rtl'>آخرین تراکنش ها</h6>
              <List>
              <ul>
                <li></li>
                <li></li>
                <li></li>
              </ul>
              </List>
            </div>
            </div>
        </Col>
      </Row>
      <h6 className='mt-3 text-muted'>تغیررات دارایی ها</h6>
      <Row>
        <div className='card-protofilo'>

        </div>
      </Row>

    </div >
  )
}
}
export default Homepage
