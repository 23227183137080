import React from 'react'
import cookie from 'react-cookies'
import axios from 'axios'
import {
  Card, Button, CardHeader, CardFooter, CardBody,
CardTitle, CardText,Nav,NavLink, Form, FormGroup, Label, Input,Container,Col,Row,Table,List} from 'reactstrap'
import {Helmet} from 'react-helmet'
import {RiFileListLine,RiFileForbidLine} from "react-icons/ri";
import {FaHandHoldingUsd,FaHandHoldingMedical} from "react-icons/fa";
import {BiDotsVertical} from "react-icons/bi";
import SimpleLineIcon from 'react-simple-line-icons'
import { withTranslation } from 'react-i18next';
import SimpleReactValidator from 'simple-react-validator'

import {Link} from 'react-router-dom'

class Profile extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      mess:[],
      trss :[],
      lists : [],
      md : [],
      gates :[],
      dgates : [],
      dt : [],
      pform : [],
      yt : [],
      sumallusd : 0,
      sumallirr : 0,
      sum : 0,
      sumyd : 0,
      loader : true,
      message : '. . . در حال دریافت اطلاعات',
      tc : 'h6 loader-message text-center'
    }
    this.validator = new SimpleReactValidator();
  }
  componentWillMount(){
    let uid = cookie.load('uid')
    // if(!uid){
    //   this.props.history.push('/Auth/login')
    // }
    this.getdashbord()
  }
  getdashbord =()=>{
    axios.get('/api/dashboard/getstate').then(response=>{
      if(response.data.status === "error-fatal"){
        document.cookie = 'uid' +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        cookie.remove('uid')
        axios.get('/Users/logout')
        setTimeout(
        () => this.props.history.push('/'),
        1000
      );
      }
      var r = response.data
      // localStorage.set
      // localStorage.clear()
      localStorage.setItem('Name', r.name);
      localStorage.setItem('email', r.email);
      localStorage.setItem('img', r.profile);
      this.setState({
        name : r.name,
        email :r.email,
        dc : r.cd.count,
        dt : r.cd.data,
        yc : r.yd.count,
        gates : r.gates,
        pform : r.personalform,
        yt : r.yd.data,
        dgc : r.dg.count,
        sum : r.cd.sum,
        sumyd : r.yd.sum,
        sumallusd : r.sumall.usd,
        sumallirr : r.sumall.irr,
        md : r.md,
        loader : false,
      })
      var name = r.name
      var profile = r.profile
      var email = r.email
      this.props.setname(name,email,profile)
    })

  }
  render(){
const { t } = this.props;

    return(
    <>
      <Helmet>
         <title>{t('profile.title')}</title>
      </Helmet>
      <div >
          <Card className='rtl'>
          <CardHeader>پروفایل کاربر
          </CardHeader>
          <CardBody>
            <div className='content bg-white py-5 px-3 border radius'>
            <p className='rtl'>تغییر رمز عبور</p>
              <Row xs="1" sm="1" md="2" lg='2' xl='4'>
                 <Col xl='6' className='m-auto text-center'>

                   <Form ref={e => (this.form = e)}>
                     <Input
                        id="password"
                        size='sm'
                        component="input"
                        name="password"
                        className="rtl my-3"
                        type="password"
                        placeholder={t('newPassword')}
                        onChange={this.onChange}
                      />
                      {this.validator.message('password', this.state.password, 'required|min:8')}
                      <Input
                         id="passconf"
                         size='sm'
                         component="input"
                         name="passconf"
                         className="rtl my-3"
                         type="password"
                         placeholder={t('Passconf')}
                         onChange={this.onChange}
                       />
                      </Form>
                    <Button type='submit' outline onClick={this.changepassword} color="primary" className='bt-50' block>{t('change.pass')}</Button>
                 </Col>
              </Row>
              <hr/>
              <p className='rtl'> تنظیمات Authenticator</p>
              <Row xs="1" sm="1" md="2" lg='2' xl='4' className='rtl'>

              </Row>
              <hr/>
              <p className='rtl'>ارسال مدارک</p>
              <Row xs="1" sm="1" md="2" lg='2' xl='4' className='rtl'>

              </Row>
            </div>
            </CardBody>
            </Card>
          </div>


    </>
    )
  }
  }
  export default withTranslation()(Profile)
