import React from 'react'
import axios from 'axios'
import {Modal,ModalBody,ModalHeader,ModalFooter, Table,TabContent, TabPane, Nav, NavItem, NavLink, Card, Button, CardTitle,CardHeader,CardBody, CardText, Row, Col } from 'reactstrap';
import classnames from 'classnames';
import SimpleDateTime  from 'react-simple-timestamp-to-date'
import moment from 'jalali-moment'
import NumericFormat from 'react-number-format';
import NumberFormat from 'react-number-format';
import SimpleLineIcon from 'react-simple-line-icons'
import SearchInput, {createFilter} from 'react-search-input'
import Loader from '../../landing/loader'
import {Helmet} from 'react-helmet'
import {withTranslation} from 'react-i18next'
class Dashboard extends React.Component{
  constructor(props){
    super(props);
    this.data = []
    this.state = {
      
    }
    this.setactive = this.setactive.bind(this)
  }
  componentWillMount(){
    this.get()
  }
  setactive =(e)=>{
    this.setState({activetab : e})
    switch (e) {
      case 1:
        this.data = this.state.all.filter(createFilter('خرید' , ['side']  ))
        break;
      case 2:
        this.data = this.state.all.filter(createFilter('فروش' , ['side']  ))
        break;
      case 3:
        this.data = this.state.all.filter(createFilter('واریز' , ['type']  ))
        break;
      case 4:
        this.data = this.state.all.filter(createFilter('برداشت' , ['type']  ))
        break;
      case 5:
        this.data = this.state.all.filter(createFilter('ناموفق' , ['status']  ))
        break;
      default:
      this.data = this.state.all

    }
  }
  get = ()=>{
    axios.get('/api/admin/transactions/').then(response=>{

      if(response.data.length === 0){
        this.setState({message : 'تراکنشی برای نمایش وجود ندارد .'})
      }
      this.data = response.data
      this.setState({ all : response.data})
    })
  }
  toggle = (i)=>{
    this.setState({activetab : i})
  }
  jdate = (x)=>{
    var x = parseInt(x * 1000)
    var today = new Date(x);
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = today.getFullYear();

  let Today = yyyy + '/' + mm + '/' + dd;
  return(
    <>{moment(Today, 'YYYY/MM/DD').locale('fa').format('YYYY/MM/DD')}</>
  )

  }
numeric =(x)=>{
  return(
  <NumericFormat value={x} thousandSeparator="," displayType="text"  className='text-dark' />

  )
}

 backdrop = (e) => {

  this.setState({backdrop : !this.state.backdrop})
}
 togglemodal = (e) => {

  this.setState({modal : !this.state.modal})
}

 changeKeyboard = (e) => {
  this.setState({ keyboard : e.currentTarget.checked})
}
getx =(i)=>{
  let type = i.type
  this.setState({order : i , modal : true})
  // axios.get('/api/transactions/get_detail/' +i.orderid).then(response=>{
  //   this.setState({order : response.data})
  // })
}
setcolor =(i)=>{
  let s = i.status
  switch (s) {
    case 'تکمیل شده':
      return 'green'
      break;
    case 'در صف واریز':
      return 'blue'
      break;
    case 'در حال انجام':
      return 'orange'
      break;
    case 'لغو شده':
      return 'red'
      break;
    default:

  }
}
toggleclose =()=>{
  this.setState({modal : false})
}
toggle =()=>{
  this.setState({modal : false})
}
  render(){
    const {t} = this.props
    const {match} = this.props
    const activeTab = this.state.activetab
    const fields = [
	{ name: 'wid', displayName: "ID", inputFilterable: true, sortable: true },
	{ name: 'date', displayName: "تاریخ", inputFilterable: true, exactFilterable: true, sortable: true },
	{ name: 'amount', displayName: "مبلغ", inputFilterable: true, exactFilterable: true, sortable: true },
	{ name: 'slug', displayName: "ارز", inputFilterable: true, exactFilterable: true, sortable: true },
	{ name: 'to', displayName: "مقصد", inputFilterable: true, exactFilterable: true, sortable: true },
	{ name: 'type', displayName: "نوع", inputFilterable: true, exactFilterable: true, sortable: true },
	{ name: 'status', displayName: "وضعیت", inputFilterable: true, exactFilterable: true, sortable: true }
];
const i = this.state.order
    return(

    <>
    <Helmet>
       <title>رپیتکس | تراکنش ها</title>
    </Helmet>
    <Modal data-aos-duration={500} data-aos='slide-right' fade={false}  className='tx-detail' isOpen={this.state.modal} fade={false} toggle={this.toggle}>
    <div className='rtl'>
      <div className='d-flex rtl  my-auto text-dark bg-white'>
        <p className='py-5 px-3 rtl'>{t('tx-detaile-title')}</p>
        <p className='py-5 px-3 ltr mr-auto'>{i.orderid}</p>

      </div>
    </div>
    <ModalBody >
      <div className='card-body pt-5 bg-white light px-3' >
      <h6 className='d-flex rtl my-3'>
      <span className='rtl ml-auto '>{i.side }</span><span className='ltr mr-auto'>{i.market !== '' ? i.market : i.quote}</span></h6>
        <Row xl='2' lg='2' xs='1' sm='1' md='2' className='tx-row '>
          <Col xs='12' sm='12' md='6' xl='12' className={i.side === 'خرید' || i.side === 'واریز' ? "bg-primary mt-3" : "bg-danger mt-3"}>
          {i.type === 'تبدیل'  ?
          <div className='px-2 '>
          <Row xl='12'>
            <Col xs='4' className='my-3'>
            <small className={i.quotename.toLowerCase() +'-icon ml-auto float-right my-auto mr-3'} />
            </Col>
            <Col xs='4' className=' mt-4'>
            <small className='text-dark my-auto text-center font-weight-bold'>
            <NumberFormat  thousandSeparator=',' displayType='text' value={i.quoteamount} />
            </small>
            </Col>
            <Col xs='4' className='mt-4'>
            <small className='text-dark mr-3 my-auto  font-weight-bold my-auto'>
            {i.quotename}
            </small>
            </Col>
            <Col className='py-3'>
            <small className={i.basename.toLowerCase() +'-icon ml-auto float-right my-auto mr-3'} />
            </Col>
            <Col className='mt-4'>
            <small className='text-dark mr-3 my-auto  font-weight-bold my-auto'>
            <NumberFormat  thousandSeparator=',' displayType='text' value={i.baseamount} />
            </small>
            </Col>
            <Col className='mt-4'>
            <small className='text-dark mr-3 my-auto  font-weight-bold my-auto'>
            {i.basename}
            </small>
            </Col>

          </Row>
          </div> :
          <h4 className='text-middle font-weight-bold'>
          <span>
          {this.numeric(i.quoteamount)}
          </span>
          <span> : مبلغ
          </span>
          </h4>

           }
          </Col>
          <Col data-aos-delay='300' data-aos='fade-in' data-aos-duration='300' xl='12' xs='12' sm='12' md='12' lg='12' className=' mt-3'>
          <Row xl='1'>
            <Col xl='12' xs='12' sm='12' md='6'  className='bg-light p-3 py-2 rtl d-flex'>
            <small className='rtl ml-auto text-small' >
            تاریخ :
            </small>
            <small className='text-small mr-auto'>
            {i.date}
            </small>
            </Col>
            <Col xl='12' xs='12' sm='12' md='6'  className='bg-light  p-3 rtl d-flex'>
            <small className='rtl ml-auto text-small' >
            نوع :
            </small>
            <small className='text-small mr-auto'>
            {i.type}
            </small>
            </Col>
            <Col xl='12' xs='12' sm='12' md='6'  className='bg-light  p-3 rtl d-flex'>
            <small className='rtl ml-auto text-small' >
            وضعیت :
            </small>
            <small className='text-small mr-auto'>
            {i.status}
            </small>

            </Col>

          </Row>

          </Col>
          <Col xl='12' xs='12' data-aos-delay='500' data-aos='fade-in' data-aos-duration='400'>
            <Row className='bg-light rtl  mt-5 py-4 p-3'>
              <Col className='py-4' xl='6' lg='6' md='6' sm='12' xs='12'>
                <small className='rtl'>  کد رهگیری : </small>
              </Col>
              <Col className='py-4 ltr' xl='6' lg='6' md='6' sm='12' xs='12'>
                <small className='ltr'>{i.txid !== '' ? i.txid : i.orderid}</small>
              </Col>
            </Row>
          </Col>
        </Row>

      </div>
    </ModalBody>
    <ModalFooter>
      <Button outline  color="dark" onClick={this.toggle}>{t('close')}</Button>
    </ModalFooter>
  </Modal>
        <Modal data-aos-duration={500} data-aos='slide-right' fade={false} isOpen={this.state.modals} className='main-tx-modal'>
          <div contentClassName ='tx-modal float-left bg-white'   isOpen={this.state.modal} className='tx-modal' >
          <ModalHeader className='d-none rtl bg-white' >
          <span className='ml-auto'>
          <SimpleLineIcon onClick={this.togglemodal} name='close ml-auto pointer' />
          </span>


          </ModalHeader>
            <div className='card-body pt-5 bg-white light' >
            <h4 className='text-muted rtl my-3'>{i.type + ' '+ i.market}</h4>
              <Row xl='2' lg='2' xs='1' sm='1' md='2' className='tx-row '>
                <Col className='bg-success mt-3'>
                {i.type === 'تبدیل'  ?
                <div className='px-2'>
                <Row xl='1'>
                  <Col className='py-2  rtl d-flex'>
                  <small className={i.quotename.toLowerCase() +'-icon ml-auto'} >

                  </small>
                  <small className='text-dark text-small mr-auto float-left font-weight-bold my-auto'>
                  {i.quoteamount}
                  </small>

                  </Col>
                  <Col className='text-dark mt-3 d-flex rtl border-top' >
                  <small className={i.basename.toLowerCase() +'-icon ml-auto'} >

                  </small>
                  <small className='text-dark text-small mr-Auto mt-3 font-weight-bold'>
                  {i.baseamount}
                  </small>
                  </Col>
                </Row>
                </div> :
                <h4 className='text-dark text-middle font-weight-bold'>
                <span className='text-dark'>
                {this.numeric(i.quoteamount)}
                </span>
                <span> : مبلغ
                </span>
                </h4>

                 }
                </Col>
                <Col className=' mt-3 '>
                <Row xl='1'>
                  <Col className='text-dark bg-light px-2 py-2 rtl d-flex'>
                  <small className='text-dark rtl ml-auto text-small' >
                  تاریخ :
                  </small>
                  <small className='text-dark text-small mr-auto'>
                  {i.date}
                  </small>
                  </Col>
                  <Col className='text-dark bg-light my-2 py-2 rtl d-flex'>
                  <small className='rtl ml-auto text-small' >
                  نوع :
                  </small>
                  <small className='text-dark text-small mr-auto'>
                  {i.type}
                  </small>
                  </Col>
                  <Col className='bg-light  py-2 rtl d-flex'>
                  <small className='rtl ml-auto text-small' >
                  وضعیت :
                  </small>
                  <small className='text-small mr-auto'>
                  {i.status}
                  </small>

                  </Col>

                </Row>

                </Col>

              </Row>
              <div className='bg-light rtl mr-3 mt-5 py-4 pl-3 pr-5 d-flex'>
              <span className='rtl'>  کد رهگیری : </span>
             <span className='mr-auto'>{i.txid !== '' ? i.txid : i.orderid}</span>
              </div>
            </div>
            <ModalFooter className='mt-auto rtl bg-white fixed-bottom' >
              <Button className='ml-auto' color="primary" onClick={this.togglemodal}>بستن</Button>
            </ModalFooter>
          </div>
       </Modal>

      <Card className='rtl mt-3'>
      <div className='scrollHider rtl'>
        <div class="menuBox">
      <a className={this.state.activetab === 0 ? 'active-tab text-center pointer text-center ml-3 ' : ' text-center ml-3 text-white text-center pointer'} onClick={()=>this.setactive(0)} >
       <span> همه تراکنش ها </span>
      </a>
      <a className={this.state.activetab === 1 ? 'mx-3 active-tab text-center pointer' : 'mx-3 text-white text-center text-center pointer'} onClick={()=>this.setactive(1)} >
         <span> خرید </span>
      </a>
      <a className={this.state.activetab === 2 ? 'mx-3 active-tab text-center pointer' : 'mx-3 text-white text-center text-center pointer'} onClick={()=>this.setactive(2)} >
         <span> فروش </span>
      </a>
      <a className={this.state.activetab === 3 ? 'mx-3 active-tab text-center pointer' : 'mx-3 text-white text-center text-center pointer'} onClick={()=>this.setactive(3)} >
         <span> واریز </span>
      </a>
      <a className={this.state.activetab === 4 ? 'mx-3 active-tab text-center pointer' : 'mx-3 text-white text-center text-center pointer'} onClick={()=>this.setactive(4)} >
         <span> برداشت </span>
      </a>
      <a className={this.state.activetab === 5 ? 'mx-3 active-tab text-center pointer' : 'mx-3 text-white text-center text-center pointer'} onClick={()=>this.setactive(5)} >
         <span> ناموفق </span>
      </a>
      <a className='mx-3 text-white text-center text-center pointer' onClick={this.get}><SimpleLineIcon name='reload' color='white' />
      </a>
      </div>
      </div>
      <CardBody className='pb-5'>

          {this.state.all.length !== 0 ? <>
             <Table borderless striped responsive>
              <thead className='bg-light  font-weight-bold border-bottom '>
              <tr className=''>
                <td>#
                </td>
                <td className='py-3'>کد تراکنش
                </td>
                <td className='py-3'>
                </td>
                <td>تاریخ
                </td>

                <td>پرداخت
                </td>
                <td>دریافت
                </td>
                <td>مارکت / ارز
                </td>
                <td>سمت
                </td>
                <td>وضعیت
                </td>

              </tr>
              </thead>
              <tbody>

            {this.data.map((i,index)=>
              <tr onClick={()=>this.getx(i)} className={i.status ===   'تکمیل شده'?'border-right-success':' border-right-fail' }>
              <td className='text-tiny py-3'>{index +1}</td>
              <td>{i.txid || i.id}</td>
              <td>{i.name}</td>
              <td className='text-center py-3'><span className='text-small  ml-2'>{i.date}</span><br/><span className='text-small text-center'>{i.time}</span></td>

              <td className='text-small py-3'><span className='my-auto'>{this.numeric(i.quoteamount)}</span></td>
              <td className='text-small py-3'><span className='my-auto'>{this.numeric(i.baseamount)}</span></td>

              <td className='text-small py-3'>{i.market === '' ? i.quote : i.market}</td>
              <td className='text-small py-3'>
                {i.side}
              </td>
              <td className='text-small py-3'>{i.status}</td>
              </tr>
            )}
            </tbody>
            </Table>
            </> :
            <h6 className='bg-light px-3 text-center text-dark'>{this.state.message}</h6> }
      </CardBody>
      </Card>

    </>
    )
  }
  }
  export default withTranslation()(Dashboard)
