import React from 'react'
import axios from 'axios'
import cookie from 'react-cookies'
import {
  FormGroup,Table,CardHeader,Card,CardBody,Row,UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem,
Button, Form, Label,Alert,  FormFeedback, FormText,Input,Container,Col} from 'reactstrap'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {Helmet} from 'react-helmet'

import {Link,withRouter} from 'react-router-dom'
import { useTranslation, withTranslation, Trans } from 'react-i18next';
import SimpleReactValidator from 'simple-react-validator'
const MySwal = withReactContent(Swal)

class Wallet extends React.Component {
     constructor(props) {
          super(props);
          this.state = {
            verified : "notverified",
            code : "",
            button : "ok",
            accounts :[]
          }
          this.validator = new SimpleReactValidator();
      }
onChange = (e) => {
      this.setState({
           [e.target.name]: e.target.value
      })
 }
onSubmitwithdraw = (e) => {
       this.check()
       const {t} = this.props
      e.preventDefault();
      if(this.state.balance < '100000'){
        console.log('حداقل موجودی برای برداشت 100000 تومان میباشد')
        return
      }
      if(parseInt(this.state.amount) > parseInt(this.state.balance)){
        console.log('موجودی حساب ریالی شما کافی نیست')
        return
      }
      if (!this.validator.fieldValid('amount')) {
        console.log(`${t('no.valid.amount')}`)
        return
      }else{

      }
      if (this.state.bankd !== true) {
        console.log(`${t('no.valid.bank')}`)
        return
      }else{

      }
      const formData = new FormData();
      formData.append('amount', this.state.amount)
      formData.append('destination', this.state.bank.shaba)
      axios.post('/api/Finance/Withdrawal_irt', formData).then(response => {
        if(response.data.type === 'fatal-error'){
          return
        }
        if(response.data.tpe === 'error'){

        }
        if(response.data.tpe === 'success'){
          console.log('برداشت شما ثبت گردید')
        }
      })
    }
onSubmit = (e) => {
       this.check()
       const {t} = this.props
      e.preventDefault();
      this.setState({
        button: ""
      })
      if (!this.validator.fieldValid('name')) {
        console.log(`${t('no.valid.name')}`)
        return
      }else{

      }
      if (!this.validator.fieldValid('bname')) {
        console.log(`${t('no.valid.bank.name')}`)
        return
      }else{

      }
      if (!this.validator.fieldValid('card')) {
        console.log(`${t('no.valid.card.number')}`)
        return
      }else{

      }
      if (!this.validator.fieldValid('iban')) {
        console.log(`${t('no.valid.iban.number')}`)
        return
      }else{

      }

      const formData = new FormData();
      formData.append('name', this.state.name)
      formData.append('bname', this.state.bname)
      formData.append('card', this.state.card)
      formData.append('iban', this.state.iban)
      axios.post('/api/Finance/add_bank_account', formData).then(response => {
        if (response.data.status === "error-fatal") {
          document.cookie = 'uid' + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
          cookie.remove('uid')
          axios.get('/Users/logout')
          setTimeout(
            () => this.props.history.push('/'),
            1000
          );
         }
          if(response.data.status === 'success') {
            this.setState({
              button: "ok",
              name: '',
              bname: '',
              card: '',
              iban: '',
              banks : [],
              bank : [],
              bankd : false,
              message : ''
            })
            this.update()
          } else {
            this.setState({
              button: "ok",
              verified: "ok"
            })
          }
        })
     }
  check = ()=>{
    if(!cookie.load('uid')){
      this.props.history.push('/')
    }
  }
  componentDidMount(){
    // this.check()
    this.update()
     }
  update =()=>{
       this.check()
       const formData = new FormData();
       formData.append('uid' , cookie.load('uid'))
       axios.post('/api/Finance/get_bank_accounts',formData).then(response=>{
             if(response.data){
               this.setState({message : response.data.message,bm : response.data.message})
             }else{
               if(response.data.data.length < 2){
                 this.setState({bm : 'برای تکمیل این بخش ثبت دو حساب بانکی ریالی الزامی میباشد.'})
               }
               this.setState({banks : response.data.data,balance : response.data.balance ,message : ''})
             }
           }
         )
       }
setbankacount =(item)=>{
  console.log(this.state.bank);
  this.setState({bankd : true ,bank : item})
}
  render(){
    const { t } = this.props;
    // console.log(this.state.data);
    return(
    <>
    <Helmet>
       <title>{t('profile.title')}</title>
    </Helmet>
<div className='pb-5'>


    <Card>
    <CardHeader className='button-row rtl'>
      <Link to='depositcrypto'  className='ms-3 text-white'>واریز</Link>
      <Link to='depositirt' className='mx-3 text-white'> واریز ریالی </Link>
      <Link to='withdrawall' className= 'text-white mx-3'>برداشت </Link>
      <Link to='withdrawirt'  className= 'mx-3 active-tabs'>برداشت ریالی</Link>    </CardHeader>
      <CardBody>
      {this.state.bm !== '' ?
           <Alert color="warning rtl">
              {this.state.bm}
            </Alert> : <></>}
        <Row>
          <Col xl={{size:8,offset : 2}} className='m-auto'>
            <div className="rtl">
              {this.state.message === '' ?
              <>
              <Form>
                <FormGroup>
                  <Label className='text-medium' for="name">مبلغ برداشت *</Label>
                  <Input type='number' min='0' onChange={this.onChange} name="amount" />
                  {this.validator.message('amount', this.state.amount, 'string|required')}
                  <FormText></FormText>
                 </FormGroup>
                <FormGroup>
                  <Label className='text-medium' for="name">انتخاب حساب *</Label>
                  <UncontrolledDropdown className='ticker-list-2 m-auto'>
                    <DropdownToggle color='light' block>
                  {this.state.bankd !== true ?  'یک حساب بانکی انتخاب کنید' :
                  <div className='d-flex justify-content-space-between'>
                    <span>{this.state.bank.bankname}</span>
                    <span className='mr-5'>{this.state.bank.accountNo}</span>
                  </div>
                }
                    </DropdownToggle>
                    <DropdownMenu className='w-100 modifier'>
                    {this.state.banks.map(item=>
                      <>
                        <DropdownItem onClick={()=>this.setbankacount(item)}>
                        <div className='d-flex justify-content-space-between'>
                          <span>{item.bankname}</span>
                          <span className='mr-5'>{item.accountNo}</span>
                        </div>
                        </DropdownItem>
                      </>
                    )}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  <FormText></FormText>
                 </FormGroup>
                 <FormGroup>
                 <Button block onClick={this.onSubmitwithdraw} color='primary' className ="mt-4">ثبت برداشت</Button>
                 </FormGroup>
                </Form>

              </>
              :
              <></>
            }
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  </div>
  <div className='pb-5'>
    <Card>
      <CardHeader className='rtl'>ثبت حساب ریالی
      </CardHeader>
      <CardBody>
        <Row>
          <Col xl={{size:8,offset : 2}}>
            <div className="rtl">
              <Form>
               <FormGroup>
                 <Label className='text-medium' for="name">نام کامل *</Label>
                 <Input value={this.state.name} onChange={this.onChange} name="name" />
                 {this.validator.message('name', this.state.name, 'string|required')}
                 <FormText></FormText>
                </FormGroup>
                              <FormGroup>
                 <Label className='text-medium' for="bname">نام بانک شما *</Label>
                 <Input value={this.state.bname} onChange={this.onChange} name="bname" />
                 {this.validator.message('bname', this.state.bname, 'string|required')}

                 <FormText></FormText>
                </FormGroup>
                <FormGroup>
                <Label className='text-medium' for="card"> شماره کارت *</Label>
                <Input value={this.state.card} onChange={this.onChange} name="card" />
                {this.validator.message('card', this.state.card, 'numeric|size:16|required')}
                <FormText></FormText>
                </FormGroup>
                <FormGroup>
                <Label className='text-medium' for="iban">شماره شبا *</Label>
                <Input value={this.state.iban} onChange={this.onChange} name="iban" />
                {this.validator.message('iban', this.state.iban, 'string|size:26|required')}
                <FormText></FormText>
                </FormGroup>
                <FormGroup>
                <Button block onClick={this.onSubmit} color='primary' className ="mt-4">ثبت حساب جدید</Button>
                </FormGroup>
              </Form>
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  </div>
    </>
    )
  }
  }
  export default withTranslation()(Wallet)
