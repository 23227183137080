import React from 'react'
import cookie from 'react-cookies'
import axios from 'axios'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {
  Card, CardHeader,FormGroup,Table, CardBody, InputGroup, InputGroupText, Input,Col,Row ,UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem,Button} from 'reactstrap'
import {Helmet} from 'react-helmet'
import {ReactQrcode} from 'reactjs-qrcode-generator'
import { useToasts } from 'react-toast-notifications'
import { withTranslation } from 'react-i18next'
import SimpleLineIcon from 'react-simple-line-icons'
import SimpleReactValidator from 'simple-react-validator'
const MySwal = withReactContent(Swal);

class Wallet extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      data : [],
      mode : 2 ,
      list : [],
      account : [],
      txs : [],
      amount : 1000000
    }
this.validator = new SimpleReactValidator();
  }
  componentWillMount(){
    this.get()
  }
get = ()=>{
  var id =this.props.match.params.id
  axios.get('/api/admin/get_account/'+id).then(response=>{
    this.setState({account : response.data.account})
  })
}
  onChange =(e)=>{
    this.setState({[e.target.name] : e.target.value})
  }


  submit = (e)=>{
    e.preventDefault()
    var id =this.props.match.params.id
    if(!this.state.amount){
      return
    }
    if(parseFloat(this.state.amount) === 0){
      return
    }
    const data = new FormData()
    data.append('id',id)
    data.append('amount',this.state.amount)
    data.append('slug',this.state.account.slug)
    axios.post('/api/admin/withdraw_account' , data).then(response=>{
      if(response.data.status === 'success'){
        this.get()
        console.log('حساب شارژ شد')
      }
    })
  }
  render(){

    const { t } = this.props;
    // console.log(this.state);
    var id =this.props.match.params.id
    var i=this.state.account
    return(
    <>

    <Card className='container rtl'>
    <CardHeader>

    </CardHeader>
          <Card>
            <CardHeader className='bg-darkblue'>
            <span className={this.state.slug + '-icon'}></span><span>
            افزایش حساب کاربر - {this.state.account.slug}
            </span>
            </CardHeader>
            <CardBody>
            <Row xl='2' lg='2' md='1'>
              <Col>
              <div>
                <Input name='amount' type='number' onChange={this.onChange}  className='my-3'/>
                <Button block color='primary' onClick={this.submit}  className='my-3'>ثبت</Button>
              </div>
              </Col>
              <Col>
              <div>

                  <Table>

                      <tr>
                      <td>موجودی کل</td>
                      <td>{i.balance}</td>
                      </tr>
                      <tr>
                      <td>موجودی قابل استفاده</td>
                      <td>{i.availablebalance}</td>
                      </tr>


                  </Table>

              </div>
              </Col>
            </Row>

            </CardBody>
            </Card>

    </Card>

    </>
    )
  }
  }
  export default withTranslation()(Wallet)
