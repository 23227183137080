import React from 'react'
import axios from 'axios'
import SimpleDateTime  from 'react-simple-timestamp-to-date'
import {
  Card, Button, CardHeader, CardFooter, CardBody,
CardTitle, CardText,Nav,NavLink, Form, FormGroup, Label, Input,Container,Col,Row,Table,List} from 'reactstrap'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import cookie from 'react-cookies'
import {RiDraftFill,RiFileListLine,RiFileForbidLine,RiFileEditLine,RiEmotionUnhappyLine } from "react-icons/ri";
import SimpleLineIcon from 'react-simple-line-icons'
import {Link} from 'react-router-dom'
import {withRouter} from 'react-router-dom'

import { useTranslation, withTranslation, Trans } from 'react-i18next';
const MySwal = withReactContent(Swal)

class Layer extends React.Component{
  constructor(props) {
       super(props);
       this.state = {
         data :[]
       }
 }

  getlist = ()=>{
    axios.get('/api/ttm/getlist').then(response=>{
      this.setState({lists : response.data})
    })
  }
    componentDidMount(){
  }

componentWillMount(){
  // if(!cookie.load('admin')){
  //   // this.props.history.push('login')
  // }
axios.get('/api/Admin/get_posts').then(response=>{
  var d = response.data
  this.setState({data : d})

})

}


  render(){
      const { t } = this.props;
    return(
      <>
      <div className='dashboard container'>
      <Link to='/admin/new_post'>Add new Post +
      </Link>
      <Row>
         <Col className='rtl'>
          {this.state.data.map(i=>
              <>
                <b>{i.subject}
                </b>
                <p>{i.content}
                </p>
              </>
          )}
         </Col>
       </Row>
      </div>
      </>
    )
  }
}
export default withRouter(withTranslation()(Layer))
