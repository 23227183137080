import React from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText
} from 'reactstrap';
import Logotext from '../../style/images/logo-text.svg'
import {Link} from 'react-router-dom'
import SimpleLineIcon from 'react-simple-line-icons'
import {useTranslation,withTranslation} from 'react-i18next'
import moment from 'jalali-moment'
import Clock from 'react-digital-clock';
import axios from 'axios'
const D = ()=>{
  var today = new Date();
var dd = String(today.getDate()).padStart(2, '0');
var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
var yyyy = today.getFullYear();

let Today = yyyy + '/' + mm + '/' + dd;
return(
  <>{moment(Today, 'YYYY/MM/DD').locale('fa').format('YYYY/MM/DD')}</>
)
}
class Example extends React.Component {
  constructor(props) {
       super(props);
       this.state = {
        name : '',
        email : '',
        user : [],
        x : 'X'
       }
   }
   componentWillMount(){
     // axios.get('/api/dashboard/getuser').then(response=>{
     // })
   }
  render(){

  return (
      <></>
  );
}
}

export default withTranslation()(Example);
