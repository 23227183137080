import React from 'react'
import cookie from 'react-cookies'
import axios from 'axios'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {
  Card, CardHeader,FormGroup,Table, Form,CardBody, InputGroup, InputGroupText, Input,Col,Row ,UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem,Button} from 'reactstrap'
import {Helmet} from 'react-helmet'
import {ReactQrcode} from 'reactjs-qrcode-generator'
import { useToasts } from 'react-toast-notifications'
import { withTranslation } from 'react-i18next'
import SimpleLineIcon from 'react-simple-line-icons'
import SimpleReactValidator from 'simple-react-validator'
const MySwal = withReactContent(Swal);

class Wallet extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      list :[],
      coinname : '',
      coinname2 : '',
      c1 : [],
      c2 : [],
      input1 : true,
      btn : true,
    }
this.validator = new SimpleReactValidator();
  }
  setcointaker = (item) =>{
    this.setState({coinname : item.name ,slug : item.slug, coinPrice : item.irprice , fee : item.fee,c1 : item})
  }
  setcointicker = (item) =>{
    if(this.state.coinname === '') {

      return
    }
    this.setState({coinname2 : item.name , input1 : false ,slug2 : item.slug , coinPrice2 : item.irprice , fee2 : item.fee,c2 : item})
  }
  onChange = (e)=>{
    if(this.state.coinname === ''){
      console.log('ارزی انتخاب نکرده اید')
      return
    }
    if(this.state.coinname2 === ''){
      console.log('ارزی انتخاب نکرده اید')
      return
    }
   var c1 =  this.state.c1
   var c2  =  this.state.c2
   var a1 =   this.state.c1.irprice
   var a2  =  this.state.c2.irprice
   var f1 =  c1.fee
   var f2 =  c2.fee * a2
   var am =  e.target.value
   var m1 =  am * a1
   var m1 = m1 - (m1/100)
   var res =  m1 / a2

   this.setState({res : res , am : am , btn : false})
  }
componentWillMount(){
  var id =this.props.match.params.id
  axios.get('/api/api/list').then(response=>{
    this.setState({list : response.data})
  })
}

swap = (e)=>{
  e.preventDefault()
  this.setState({btn : true})
}

  render(){

    const { t } = this.props;
    // console.log(this.state);
    var id =this.props.match.params.id
    return(
    <>
    <Helmet>
       <title>{t('profile.title')}</title>
    </Helmet>
    <div className=' rtl'>
    <div id='exchange'>

    </div>
    </div>

    </>
    )
  }
  }
  export default withTranslation()(Wallet)
