import React from 'react'
import axios from 'axios'
import cookie from 'react-cookies'
import SimpleReactValidator from 'simple-react-validator'
import {useTranslation,withTranslation} from 'react-i18next'
import {Row,Col, Button,Container,Form,FormFeedback,FormGroup,Label,FormText,InputGroup,InputGroupText,Input} from 'reactstrap'
import NumericFormat from 'react-number-format';
import {Link,withRouter,Redirect} from  'react-router-dom'
import Toast from 'awesome-toast-component'
import {Helmet} from 'react-helmet'
class Login extends React.Component{
  constructor(props){
    super(props);
    const {t} = this.props
    this.state = {
        message : `${t('please enter code !')}`,
        email : '',
        password : '',
        mode : 'login',
        active2 :false
    }
    this.validator = new SimpleReactValidator();
  }



  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  adminlogin = (e) => {
    e.preventDefault()
    const {t} = this.props;
    if (!this.validator.fieldValid('email')) {
      console.log(`${t('enter.valid.email')}`)
      return
    }else{

    }
    if (!this.validator.fieldValid('password')) {
      console.log(`${t('enter.valid.password')}`)
      return
    }else{

    }
    this.setState({active2 : true})
    var d = this.state.password;
    const formdata = new FormData()
    formdata.append('email',this.state.email)
    formdata.append('password',d )
    axios.post('/api/signin' , formdata).then(response=> {
      this.setState({active2 : false})
    let data =  response.data
    if(data.status === 'error'){
        new Toast(`${t('wrong.password.email')}`, {
          style: {
              container: [
                  ['background-color', 'red']
              ],
              message: [
                  ['color', '#eee'],
              ],
              bold: [
                  ['font-weight', '900'],
              ]
          },
          timeout : 3000
      })






    }
    if(response.data.status === 'success'){
      new Toast(`${t('ورود موفق')}`, {
        style: {
            container: [
                ['background-color', 'blue']
            ],
            message: [
                ['color', '#eee'],
            ],
            bold: [
                ['font-weight', '900'],
            ]
        },
        timeout : 3000,
        position : 'top'
    })
    this.props.history.push('/admin/dashboard')
    return
    }
    })
  }



  submitotp =(e)=>{
    e.preventDefault();
    const { t } = this.props;
    const formdata = new FormData()
    formdata.append('otp',this.state.otp)
    formdata.append('auth',this.state.auth)
    axios.post('/api/signin/get_otp' ,formdata)
    .then(response=> {
    if(response.data.status === 'error'){
      new Toast(`${t('wrong.otp')}`, {
        style: {
            container: [
                ['background-color', 'red']
            ],
            message: [
                ['color', '#eee'],
            ],
            bold: [
                ['font-weight', '900'],
            ]
        },
        timeout : 3000,
        position : 'top'
    })
    return
        this.setState({mode : 'login'})
    }
      if(response.data.status === 'success'){
        new Toast(`${t('success.login')}`, {
          style: {
              container: [
                  ['background-color', 'green']
              ],
              message: [
                  ['color', '#eee'],
              ],
              bold: [
                  ['font-weight', '900'],
              ]
          },
          timeout : 3000,
          position : 'top'
      })
      this.props.history.push('/panel/dashboard')
      return
      }
    });
  }






  UNSAFE_componentWillMount(){

    let uuid = cookie.load('uid')
    if(uuid){
      this.props.history.push('/admin/dashboard')
    }
  }

  redirect =()=>{

  }

  render(){
    const {t} = this.props
    return(
      <>
      <Helmet>
        <title>{t('login-title')}</title>
      </Helmet>
                <Row className='rtl pt-5'>

                <Col xl='4' lg='4' className='m-auto'>
                <h4 className='text-center'>ورود ادمین</h4>

                  <Form>
                    <FormGroup className='mt-5'>
                    <Label>
                      {t('ایمیل')}
                    </Label>
                    <Input name='email'  onChange={this.onChange} />
                    {this.validator.message('email', this.state.email, 'required')}
                    <FormFeedback>
                    </FormFeedback>
                    <FormText>
                    </FormText>
                  </FormGroup>
                  <FormGroup className='mb-5 mt-4'>
                  <div className='forgot-note d-flex'>
                    <Label>
                    {t('رمز عبور')}
                  </Label>
                    </div>
                    <Input type='password' name='password'  onChange={this.onChange} />
                    {this.validator.message('password', this.state.password, 'required')}
                  </FormGroup>
                  <FormGroup className='text-center mt-5 border-radius'>
                    <Button onClick={this.adminlogin} disabled={this.state.active2} block type='submit' color='primary'  className='input-addon-home pointer mt-5 py-2'>{t('login')}
                    </Button>
                  </FormGroup>
                </Form>
              </Col>
                </Row>
              </>
    )
  }
}
export default withRouter(withTranslation()(Login))
const Rd = ()=>{
  return(<Redirect path='/panle/dashboard' />)
}
