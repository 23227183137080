import React from 'react'
import cookie from 'react-cookies'
import axios from 'axios'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {
  Card, CardHeader,FormGroup,Table, CardBody, InputGroup, InputGroupText, Input,Col,Row ,UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem,Button} from 'reactstrap'
import {Helmet} from 'react-helmet'
import {ReactQrcode} from 'reactjs-qrcode-generator'
import { useToasts } from 'react-toast-notifications'
import { withTranslation } from 'react-i18next'
import SimpleLineIcon from 'react-simple-line-icons'
import SimpleReactValidator from 'simple-react-validator'
import {Link} from 'react-router-dom'
const MySwal = withReactContent(Swal);

class Wallet extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      data : [],
      mode : 2 ,
      key : '1' ,
      list : [],
      c1 : [],
      txs : [],
      coinname : ''
    }
this.validator = new SimpleReactValidator();
  }
  componentWillMount(){
    let uuid = cookie.load('uid')
    if(!uuid) {
      this.props.history.push('/')
    }
    this.getwallet()
  }
  getwallet =()=>{

          this.setState({mode : 2})
    axios.get('/api/wallet/deposit/all').then(response=>{
      this.setState({list :  response.data})
    })

  }


  setcointaker = (item) =>{
    this.setState({coinname : item.irname , coinPrice : item.irprice , fee : item.fee,c1 : item})
    // this.copyaddress()
  }
  copyaddress =()=>{
    const { t } = this.props
    if(this.state.address !== ''){
      navigator.clipboard.writeText(this.state.c1.index0)
      console.log(
        <div dir='rtl'>

          <span className='rtl'>آدرس کپی شد</span>
        </div>,
      );
      }

  }

  chargewallet =()=>{
    if(this.state.verification === "0"){
      console.log('امکان واریز قبل از احراز هویت مقدور نیست')
      return
    }
    if (!this.validator.fieldValid('amount')) {
      console.log('مبلغ را به صورت صحیح وارد کنید')
      return
    }else{

    }
    let amount = this.state.amount
    let name = this.state.name
    let email = this.state.email
    let phone = this.state.mobile
    var url = 'https://idpay.ir/zarrinpayment?amount='+amount +'&name=' + name + '&desc='+email + '&phone='+phone
    window.open(url, "_blank")
  this.setState({modal : !this.state.modal})
  }
  onchange =(e)=>{
    this.setState({[e.target.name] : e.target.value})
  }
  render(){

    const { t } = this.props;
    // console.log(this.state);
    var id =this.props.match.params.id
    return(
    <>
    <Helmet>
       <title>{t('profile.title')}</title>
    </Helmet>

    <div className=' rtl'>


          <Card>
          <CardHeader className='button-row rtl'>
            <Link to='depositcrypto'  className={this.state.key === "1" ? 'active-tabs' : 'ms-3 text-white'}>واریز</Link>
            <Link to='depositirt' className='mx-3 text-white'> واریز ریالی </Link>
            <Link to='withdrawall' className='mx-3 text-white'>برداشت </Link>
            <Link to='withdrawirt' className='mx-3 text-white'>برداشت ریالی</Link>
          </CardHeader>
            <CardBody>

            <Row xl='2'>
              <Col xl='8' className='my-auto' >
              <div   className='ltr'>
              <UncontrolledDropdown className='ticker-list-deposit ml-auto'>
                <DropdownToggle color='light' block>
              {this.state.coinname === '' ?  'یک ارز انتخاب کنید' :
              <div className='d-flex justify-content-space-between'>
                <span className={this.state.c1.slug+'-icon cr-icon'}></span>

                <span className='mr-5'>{this.state.coinname}</span>
                <span className='slug-crypto mr-auto'>({this.state.c1.slug})</span>

              </div>
            }
                </DropdownToggle>
                <DropdownMenu className='w-100 modifier'>
                {this.state.list.map(item=>
                  <>
                    <DropdownItem active={item.usname === 'usdt' ? true : false} data-name={item.irname} id={item.slug} data-name={item.usname} onClick={()=>this.setcointaker(item)}>
                    <div className='d-flex justify-content-space-between'>
                      <span className={item.slug+'-icon cr-icon'}></span>
                      <span className='mr-5 text-dark'>{item.irname}</span>
                      <span className='slug-crypto mr-auto'>({item.slug})</span>
                    </div>
                    </DropdownItem>
                    <DropdownItem divider />
                  </>
                )}
                </DropdownMenu>
              </UncontrolledDropdown>
              </ div>
              <div className='add-container' dir='rtl'>
                <InputGroup>

                  <InputGroupText onClick={this.copyaddress}>
                    کپی
                  </InputGroupText>
                  <Input value={this.state.c1.index0} placeholder="" />
                </InputGroup>
              </div>
              </Col>
              <Col>
              <div className='mt-3 text-center  qrcode-container'>
               <ReactQrcode qrvalue={this.state.c1.index0} size='150' level='1' />
               </div>
              </Col>
            </Row>

            </CardBody>
            </Card>
    </div>

    </>
    )
  }
  }
  export default withTranslation()(Wallet)
