// "use strict";
import React, { useMemo, useState, StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-community/styles/ag-theme-material.css";
import { ColDef } from "ag-grid-community";
import { useHistory } from "react-router-dom";

const GridExample = (props) => {
  let history = useHistory();
  const CustomButtonComponent = (id) => {

    // console.log(id.data.id);
    history.push('/admin/User-detail/'+id.data.userid)
  };
  const [columnDefs, setColumnDefs] = useState([
    {
      field: "email",
      headerName : 'ایمیل',
      onCellClicked: (params) => CustomButtonComponent(params)
    },

    { field: "name",headerName : 'نام' },
    { field: "from",headerName : 'کارت' },


    { headerName : 'تاریخ ثبت' , field: "date" },
    { headerName : 'پرداخت' , field: "quoteamount" },

    { headerName : 'دریافت' , field: "baseamount" },
    { headerName : 'نوع' , field: "side" },
     {headerName : 'ارز' ,field: "market"},
    { headerName : 'ووچر' , field: "vnum" },
    { headerName : 'کد ووچر' , field: "vcode" },


    { headerName : 'وضعیت' , field: "status" },
  ]);
// const colDefs = [{ ...{
// headerName: "View",
// field: "name",
// colId: "view",
// cellRenderer: function(params) {
// return <button className='btn-primary' onClick={console.log(params)}>{params.name}</button>
// },
// },
// }]

  const defaultColDef = useMemo(() => {
    return {
      filter: "agTextColumnFilter",
      floatingFilter: true,
    };
  }, []);

  return (
    <div className="ag-theme-quartz" style={{ height: 700 }}>
      <AgGridReact
        rowData={props.data}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        rowSelection="multiple"
        suppressRowClickSelection={true}
        pagination={true}
        paginationPageSize={10}
        paginationPageSizeSelector={[10, 30, 40,50,60,100]}
      />
    </div>
  );
};
export default GridExample
