import React from 'react'
import cookie from 'react-cookies'
import axios from 'axios'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import {
  Card, CardHeader,FormGroup,Table, CardBody, InputGroup, InputGroupText, Input,Col,Row ,UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem,Button} from 'reactstrap'
import {Helmet} from 'react-helmet'
import {ReactQrcode} from 'reactjs-qrcode-generator'
import { useToasts } from 'react-toast-notifications'
import { withTranslation } from 'react-i18next'
import SimpleLineIcon from 'react-simple-line-icons'
import SimpleReactValidator from 'simple-react-validator'
import {Link} from 'react-router-dom'
const MySwal = withReactContent(Swal);

class Wallet extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      data : [],
      mode : 2 ,
      list : [],
      c1 : [],
      txs : [],
    }
this.validator = new SimpleReactValidator();
  }

  render(){
const {t} = this.props
    return(
    <>
    <Helmet>
       <title>{t('profile.title')}</title>
    </Helmet>

    <div className='rtl'>
      <>
        <Card className='shadow'>
        <CardHeader className='button-row rtl'>
          <Link to='depositcrypto'  className='ms-3 text-white'>واریز</Link>
          <Link to='depositirt'  className= 'active-tabs mx-3'> واریز ریالی </Link>
          <Link to='withdrawall'  className='mx-3 text-white'>برداشت </Link>
          <Link to='withdrawirt'  className='mx-3 text-white'>برداشت ریالی</Link>
        </CardHeader>
        <CardBody className='pb-5'>
        <Row className='rtl'>
          <Col xl={{size:8 , offset :2}} className='m-auto'>

          </Col>
        </Row>
        <Row>
        <Col>

        </Col>

        </Row>
        </CardBody>
        </Card>

        </>

    </div>

    </>
    )
  }
  }
  export default withTranslation()(Wallet)
